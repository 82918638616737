// extracted by mini-css-extract-plugin
export var base = "MarketingSocialPlatform-module--base--2JV63";
export var rightAlignedContainer = "MarketingSocialPlatform-module--rightAlignedContainer--1U4c_";
export var rightAlignedContainerSmall = "MarketingSocialPlatform-module--rightAlignedContainerSmall--2MLA6";
export var leftAlignedContainer = "MarketingSocialPlatform-module--leftAlignedContainer--3JLHX";
export var blogContainer = "MarketingSocialPlatform-module--blogContainer--aAccy MarketingSocialPlatform-module--base--2JV63";
export var container = "MarketingSocialPlatform-module--container--1d1dm MarketingSocialPlatform-module--base--2JV63";
export var largeContainer = "MarketingSocialPlatform-module--largeContainer--3G1PS MarketingSocialPlatform-module--base--2JV63";
export var mobileSmallPadding = "MarketingSocialPlatform-module--mobileSmallPadding--2h_om";
export var fullWidthMobile = "MarketingSocialPlatform-module--fullWidthMobile--2XMyl";
export var gridWithSidebar = "MarketingSocialPlatform-module--gridWithSidebar--3cSTd";
export var noMaxWidth = "MarketingSocialPlatform-module--noMaxWidth--77BQf";
export var socialPlatformCallout = "MarketingSocialPlatform-module--socialPlatformCallout--1l5eD";
export var featuredTemplates = "MarketingSocialPlatform-module--featuredTemplates--1NAbv";
export var tipsListTabs = "MarketingSocialPlatform-module--tipsListTabs--7MgWV";
export var successStory = "MarketingSocialPlatform-module--successStory--3O0fT";
export var howToOrderedList = "MarketingSocialPlatform-module--howToOrderedList--30qbd";
export var pitch = "MarketingSocialPlatform-module--pitch--130-E";
export var community = "MarketingSocialPlatform-module--community--3sFSJ";
export var tealBlob = "MarketingSocialPlatform-module--tealBlob--OjfJn";
export var greenSplatsComplete = "MarketingSocialPlatform-module--greenSplatsComplete--2Bk-7";
export var yellowBeanSplat = "MarketingSocialPlatform-module--yellowBeanSplat--3pFgn";
export var tealBall = "MarketingSocialPlatform-module--tealBall--3sw5H";
export var mediaHero = "MarketingSocialPlatform-module--mediaHero--3v5B3";
export var greenBlob = "MarketingSocialPlatform-module--greenBlob--8NmKE";
export var redSplatsComplete = "MarketingSocialPlatform-module--redSplatsComplete--1GeHU";
export var tipsListTabsModule = "MarketingSocialPlatform-module--tipsListTabsModule--2Nc-F";