import '../../utils/marketingPageDataFragment';

import {
  GreenBlob,
  GreenSplatsComplete,
  RedSplatsComplete,
  TealBall,
  TealBlob,
  YellowBeanSplat } from '@animoto/components';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import LazyLoad from 'react-lazyload';
import MediaQuery from 'react-responsive';

import { AnimotoLayout } from '../../components/AnimotoLayout';
import CommunityModule, { CommunityDataPropsObject } from '../../components/CommunityModule';
import FeaturedTemplatesModule, { FeaturedTemplatesDataPropsObject } from '../../components/FeaturedTemplatesModule';
import HeroModule, { HeroDataPropsObject } from '../../components/HeroModule';
import HowToOrderedListModule, { HowToOrderedListDataPropsObject } from '../../components/HowToOrderedListModule';
import PitchModule, { PitchDataPropsObject } from '../../components/PitchModule';
import SocialPlatformCalloutModule, { SocialPlatformCalloutDataPropsObject } from '../../components/SocialPlatformCalloutModule';
import SuccessStoryModule, { SuccessStoryDataPropsObject } from '../../components/SuccessStoryModule';
import TipsListTabsModule, { TipsListTabsDataPropsObject } from '../../components/TipsListTabsModule';
import componentsQueryResultToData from '../../utils/componentsQueryResultToData';
import getSeoInfo from '../../utils/getSeoInfoForMarketingPage';
import {
  community as communityClass,
  featuredTemplates as featuredTemplatesClass,
  greenBlob,
  greenSplatsComplete,
  howToOrderedList as howToOrderedListClass,
  mediaHero,
  pitch as pitchClass,
  redSplatsComplete,
  socialPlatformCallout as socialPlatformCalloutClass,
  successStory as successStoryClass,
  tealBall,
  tealBlob,
  tipsListTabs as tipsListTabsClass,
  yellowBeanSplat
} from './MarketingSocialPlatform.module.css';

export default function MarketingSocialPlatformPage({ data }) {
  const { page } = data;
  const {
    canonicalUrlPage,
    components,
    description,
    imageFieldName,
    showSiteNav
  } = page;

  const {
    community,
    featuredTemplates,
    hero,
    howToOrderedList,
    pitch,
    socialPlatformCallout,
    successStory,
    tipsListTabs
  } = componentsQueryResultToData(components);

  const SEOInfo = getSeoInfo(page, description, canonicalUrlPage, imageFieldName, hero);

  return (
    <AnimotoLayout SEOInfo={SEOInfo} showSiteNav={showSiteNav}>
      <HeroModule className={mediaHero} data={hero} />
      <SocialPlatformCalloutModule className={socialPlatformCalloutClass} data={socialPlatformCallout} />
      <FeaturedTemplatesModule className={featuredTemplatesClass} data={featuredTemplates} />
      <TipsListTabsModule className={tipsListTabsClass} data={tipsListTabs} />
      {successStory && (
        <SuccessStoryModule
          blobs={(
            <LazyLoad once>
              <MediaQuery maxWidth={767}>
                <TealBlob className={tealBlob} />
              </MediaQuery>
            </LazyLoad>
          )}
          className={successStoryClass}
          data={successStory}
          layout="asset-left"
        />
      )}
      <HowToOrderedListModule className={howToOrderedListClass} data={howToOrderedList} />
      <PitchModule
        blobs={(
          <>
            <LazyLoad once>
              <GreenSplatsComplete className={greenSplatsComplete} />
              <TealBall className={tealBall} />
              <YellowBeanSplat className={yellowBeanSplat} />
              <MediaQuery minWidth={768}>
                <GreenBlob className={greenBlob} />
                <RedSplatsComplete className={redSplatsComplete} />
              </MediaQuery>
            </LazyLoad>
          </>
        )}
        className={pitchClass}
        data={pitch}
      />
      <CommunityModule className={communityClass} data={community} />
    </AnimotoLayout>
  );
}

export const pageQuery = graphql`
  query MarketingSocialPlatformPageByUrl($slug: String!) {
    page : contentfulMarketingPage(slug: { eq: $slug }) {
      ...MarketingPageData
    }
  }
`;

MarketingSocialPlatformPage.propTypes = {
  data : PropTypes.shape({
    page : PropTypes.shape({
      canonicalUrlPage : PropTypes.shape({
        slug : PropTypes.string
      }),
      components : PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentCommunity']),
          ...CommunityDataPropsObject
        }),
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentFeaturedTemplates']),
          ...FeaturedTemplatesDataPropsObject
        }),
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentHero']),
          ...HeroDataPropsObject
        }),
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentHowToOrderedList']),
          ...HowToOrderedListDataPropsObject
        }),
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentPitch']),
          ...PitchDataPropsObject
        }),
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentSocialPlatformCallout']),
          ...SocialPlatformCalloutDataPropsObject
        }),
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentSuccessStory']),
          ...SuccessStoryDataPropsObject
        }),
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentTipsListTabs']),
          ...TipsListTabsDataPropsObject
        })
      ])).isRequired,
      description : PropTypes.shape({
        description : PropTypes.string
      }),
      imageFieldName : PropTypes.shape({
        file : PropTypes.shape({
          url : PropTypes.string
        })
      }),
      noIndex     : PropTypes.bool,
      showSiteNav : PropTypes.bool,
      slug        : PropTypes.string.isRequired,
      title       : PropTypes.string.isRequired,
      type        : PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};
