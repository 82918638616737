import { SocialPlatformCallout, useClient } from '@animoto/components';
import classNames from 'classnames';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Suspense } from 'react';

import { container, fallback, marginContainer } from './SocialPlatformCalloutModule.module.css';

const EventbritePartnerIcon = React.lazy(() => import('@animoto/components/lib/es/Icon/EventbritePartnerIcon'));
const FacebookPartnerIcon = React.lazy(() => import('@animoto/components/lib/es/Icon/FacebookPartnerIcon'));
const HubspotPartnerIcon = React.lazy(() => import('@animoto/components/lib/es/Icon/HubspotPartnerIcon'));
const LinkedinPartnerIcon = React.lazy(() => import('@animoto/components/lib/es/Icon/LinkedinPartnerIcon'));
const TikTokPartnerIcon = React.lazy(() => import('@animoto/components/lib/es/Icon/TikTokPartnerIcon'));
const TwitterPartnerIcon = React.lazy(() => import('@animoto/components/lib/es/Icon/TwitterPartnerIcon'));
const YoutubePartnerIcon = React.lazy(() => import('@animoto/components/lib/es/Icon/YoutubePartnerIcon'));

export default function SocialPlatformCalloutModule({
  className,
  data
}) {
  const {
    copy,
    ctaTrackingClass,
    ctaUrl,
    hasMarginBottom,
    partnerName,
    title
  } = data;

  let PartnerIcon;

  switch (partnerName) {
    case 'eventbrite':
      PartnerIcon = EventbritePartnerIcon;
      break;
    case 'facebook':
      PartnerIcon = FacebookPartnerIcon;
      break;
    case 'hubspot':
      PartnerIcon = HubspotPartnerIcon;
      break;
    case 'linkedin':
      PartnerIcon = LinkedinPartnerIcon;
      break;
    case 'tiktok':
      PartnerIcon = TikTokPartnerIcon;
      break;
    case 'twitter':
      PartnerIcon = TwitterPartnerIcon;
      break;
    case 'youtube':
      PartnerIcon = YoutubePartnerIcon;
      break;
    default:
      break;
  }

  if (!useClient()) {
    return <div />;
  }

  const classes = classNames(className, container, {
    [marginContainer] : hasMarginBottom
  });

  return (
    <Suspense fallback={<span className={fallback} />}>
      <SocialPlatformCallout
        className={classes}
        copy={copy.copy}
        ctaTrackingClass={ctaTrackingClass}
        ctaUrl={ctaUrl}
        partnerIcon={<PartnerIcon />}
        title={title}
      />
    </Suspense>
  );
}

export const SocialPlatformCalloutDataPropsObject = {
  copy : PropTypes.shape({
    copy : PropTypes.string.isRequired
  }).isRequired,
  ctaTrackingClass : PropTypes.string,
  ctaUrl           : PropTypes.string,
  hasMarginBottom  : PropTypes.bool,
  partnerName      : PropTypes.string.isRequired,
  title            : PropTypes.string.isRequired
};

SocialPlatformCalloutModule.propTypes = {
  className : PropTypes.string,
  data      : PropTypes.shape(SocialPlatformCalloutDataPropsObject).isRequired
};

SocialPlatformCalloutModule.defaultProps = {
  className : ''
};

export const SocialPlatformCalloutModuleData = graphql`
  fragment SocialPlatformCalloutModuleData on ContentfulComponentSocialPlatformCallout {
    copy {
      copy
    }
    ctaTrackingClass
    ctaUrl
    hasMarginBottom
    partnerName
    title
  }
`;
