// extracted by mini-css-extract-plugin
export var base = "SocialPlatformCalloutModule-module--base--XhEIR";
export var rightAlignedContainer = "SocialPlatformCalloutModule-module--rightAlignedContainer--EQmmO";
export var rightAlignedContainerSmall = "SocialPlatformCalloutModule-module--rightAlignedContainerSmall--1gjpE";
export var leftAlignedContainer = "SocialPlatformCalloutModule-module--leftAlignedContainer--_JEZT";
export var blogContainer = "SocialPlatformCalloutModule-module--blogContainer--2kjIJ SocialPlatformCalloutModule-module--base--XhEIR";
export var container = "SocialPlatformCalloutModule-module--container--1POv_ SocialPlatformCalloutModule-module--base--XhEIR SocialPlatformCalloutModule-module--container--1POv_ SocialPlatformCalloutModule-module--base--XhEIR";
export var largeContainer = "SocialPlatformCalloutModule-module--largeContainer--1izpv SocialPlatformCalloutModule-module--base--XhEIR";
export var mobileSmallPadding = "SocialPlatformCalloutModule-module--mobileSmallPadding--L7LXN";
export var fullWidthMobile = "SocialPlatformCalloutModule-module--fullWidthMobile--2eE5_";
export var gridWithSidebar = "SocialPlatformCalloutModule-module--gridWithSidebar--3zRBH";
export var noMaxWidth = "SocialPlatformCalloutModule-module--noMaxWidth--2IgMU";
export var fallback = "SocialPlatformCalloutModule-module--fallback--3AfaT";
export var marginContainer = "SocialPlatformCalloutModule-module--marginContainer--1RfMR";